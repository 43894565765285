import { getAlarmsByRoomIdUrl } from "./api-url.constants";
import { AlarmsByRoomIdApiDTO } from "./dtos/alarms-by-room-id.api.dto";
import { mapAlarmsApiDTO } from "./mappers";
import { Alarm } from "../../../models/alarm";
import { jsonFetch } from "@/lib/helpers";

export const getAlarmsByRoomId = async (roomId: string): Promise<Alarm[]> => {
  const alarmsDTO = await jsonFetch<AlarmsByRoomIdApiDTO>(getAlarmsByRoomIdUrl(roomId));
  return mapAlarmsApiDTO(alarmsDTO.data);
};
