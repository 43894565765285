import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useDialog } from "./dialog.hook";

type DialogProps = {
  isOpen: boolean;
  handleClick: () => void;
  handleClose: () => void;
  texts: { title: string; message: string; agree: string; cancel: string };
};

export const DialogComponent = ({ isOpen, handleClose, handleClick, texts }: DialogProps) => {
  const { handleAgree, handleCloseDialog } = useDialog({
    handleClose,
    handleClick,
  });
  const { title, message, agree, cancel } = texts;
  return (
    <Dialog open={isOpen} onClose={handleCloseDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{cancel}</Button>
        <Button style={{ color: "white" }} variant={"contained"} onClick={handleAgree} autoFocus>
          {agree}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
