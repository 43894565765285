import { PaxDTO } from "../dtos";
import { OccupancyLevel, Pax } from "@/models";

const mapOccupancyLevel = (
  level: "low" | "normal" | "high" | "very high" | null
): OccupancyLevel | null => {
  switch (level) {
    case "very high":
      return OccupancyLevel.Full;
    case "high":
      return OccupancyLevel.High;
    case "normal":
      return OccupancyLevel.Medium;
    case "low":
      return OccupancyLevel.Low;
    default:
      return null;
  }
};

export const mapPaxDtoToPax = (paxDto: PaxDTO): Pax => {
  return {
    pax: mapOccupancyLevel(paxDto.pax),
    startDate: paxDto.startDate,
    endDate: paxDto.endDate,
  };
};
