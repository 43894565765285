import { styled } from "@mui/material";
import Icon from "@mui/material/Icon";
import Select from "@mui/material/Select";

import MDTypography from "../md-typography";

export const CustomSelect = styled(Select)(({ theme }) => ({
  cursor: "pointer",
  border: `1px solid ${theme.palette.linearGrey.main}`,
  borderRadius: theme.spacing(1),
  boxSizing: "border-box",
  padding: `${theme.spacing(1.375)} ${theme.spacing(1.875)}`,
  backgroundColor: theme.palette.white.main,
  gap: theme.spacing(1),
  "& .MuiSelect-icon": {
    color: theme.palette.gray[10],
  },
  height: theme.spacing(5.25),

  "& .MuiSelect-select": {
    padding: "0px !important",
    paddingRight: `${theme.spacing(3)} !important`,
    ".MuiTypography-root": {
      color: theme.palette.linearGrey.main,
      fontSize: theme.spacing(1.75),
      fontWeight: 400,
      lineHeight: theme.spacing(2.45),
    },
  },
  "& .MuiSelect-select.MuiSelect-select": {
    padding: "0px !important",
    paddingRight: `${theme.spacing(3)} !important`,
  },
  "& fieldset": {
    border: "none",
  },
  "&:hover": {
    borderColor: theme.palette.linearGrey.main,
  },
  "&.Mui-focused": {
    borderColor: theme.palette.linearGrey.main,
  },
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  color: "inherit",
  width: `${theme.spacing(3)} !important`,
  height: `${theme.spacing(3)} !important`,
  fontSize: `${theme.spacing(3)} !important`,
}));

export const StyledOptions = styled(MDTypography)(({ theme }) => ({
  color: theme.palette.linearGrey.main,
  fontSize: theme.spacing(1.75),
  fontWeight: 400,
  lineHeight: theme.spacing(2.45),
}));
