import React, { useMemo } from "react";

import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { Line } from "react-chartjs-2";

import MDBox from "../md-box";
import { getChartOptions } from "./noise-raw-line-chart.options";

Chart.register(annotationPlugin);

interface NoiseRawLineChartComponentProps {
  values: number[];
  scales: {
    y: {
      suggestedMin: number;
      suggestedMax: number;
    };
  };
  annotations: {
    min: number;
    medium: number;
    max: number;
  };
  styles: {
    width: number | string;
    height: number | string;
    lineBorderColor: string;
    annotationBorderColor: string;
  };
}

export const NoiseRawLineChartComponent: React.FC<NoiseRawLineChartComponentProps> = ({
  values,
  scales,
  annotations,
  styles: { width, height, lineBorderColor, annotationBorderColor },
}) => {
  const chartData = useMemo(() => {
    return {
      labels: Array.from({ length: values.length }, (_, i) => i),
      datasets: [
        {
          data: values,
          borderColor: lineBorderColor,
          borderWidth: 1,
          tension: 0.5,
          pointRadius: 0,
          pointHoverRadius: 0,
        },
      ],
    };
  }, [values, lineBorderColor]);

  const chartOptions = useMemo(
    () => getChartOptions({ scales, annotationBorderColor, annotations }),
    [scales, annotationBorderColor]
  );

  return (
    <MDBox width={width} height={height}>
      <Line
        data={chartData}
        options={{
          ...chartOptions,
          scales: {
            ...chartOptions.scales,
            y: {
              ...chartOptions.scales?.y,
              min: scales.y.suggestedMin,
              max: scales.y.suggestedMax,
            },
          },
        }}
      />
    </MDBox>
  );
};
