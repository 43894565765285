import { styled } from "@mui/material";

import { CardComponent } from "../card";
import MDBox from "../md-box";
import MDTypography from "../md-typography";

export const StyledTitleIconCard = styled(CardComponent)({
  display: "flex",
  flexDirection: "column",
  gap: 1,
});

export const StyledTitleIconCardHeaderBox = styled(MDBox)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  height: theme.spacing(9),
  padding: theme.spacing(2),
  boxShadow: `0px 1px 0px 0px ${theme.palette.card.divider}`,
}));

export const StyledTitleIconCardHeaderIconBox = styled(MDBox)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1),
  color: theme.palette.dark.main,
  backgroundColor: theme.palette.icons.background,
}));

export const StyledTitleIconCardHeaderTypography = styled(MDTypography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  lineClamp: 2,
  color: "black",
});
