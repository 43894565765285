import React from "react";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useInternationalization } from "@/lib/contexts";

type MDToggleBoxButtonProps = {
  selectedDays: boolean[];
  onDaysChange: (newDays: number[]) => void;
};

export const MDToggleBoxButton: React.FC<MDToggleBoxButtonProps> = ({
  selectedDays,
  onDaysChange,
}) => {
  const { t } = useInternationalization("components.toggle-box-button");
  const daysOfWeekLabels = [t("L"), t("M"), t("X"), t("J"), t("V"), t("S"), t("D")];
  return (
    <div>
      <ToggleButtonGroup
        value={selectedDays
          .map((isActive, index) => (isActive ? index : null))
          .filter((index) => index !== null)}
        onChange={(event, newDays) => onDaysChange(newDays)}
        aria-label="days of the week"
        sx={{
          display: "flex",
          borderRadius: "24px",
          overflow: "hidden",
          border: "1px solid #ccc",
        }}
      >
        {daysOfWeekLabels.map((day, index) => (
          <ToggleButton
            key={index}
            value={index}
            selected={selectedDays[index]}
            aria-label={day}
            sx={{
              flex: "1 1 auto",
              border: "none",
              padding: "4px 8px",
              fontSize: "0.875rem",
              "&.Mui-selected": {
                backgroundColor: "#a4a0e6",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#40e0d0",
                },
              },
              "&:not(.Mui-selected)": {
                backgroundColor: "#f0f0f0",
                color: "#000",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              },
              "&:first-of-type": {
                borderTopLeftRadius: "24px",
                borderBottomLeftRadius: "24px",
              },
              "&:last-of-type": {
                borderTopRightRadius: "24px",
                borderBottomRightRadius: "24px",
              },
            }}
          >
            {day}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default MDToggleBoxButton;
