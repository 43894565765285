import { ChartOptions as ChartJSOptions } from "chart.js";

interface NoiseRawLineChartOptions {
  annotationBorderColor: string;
  annotations: {
    min: number;
    medium: number;
    max: number;
  };
  scales: {
    y: {
      suggestedMin: number;
      suggestedMax: number;
    };
  };
}

export const getChartOptions = ({
  annotationBorderColor,
  annotations: { min, medium, max },
  scales: {
    y: { suggestedMin, suggestedMax },
  },
}: NoiseRawLineChartOptions): ChartJSOptions<"line"> => ({
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 0,
    autoPadding: false,
  },
  plugins: {
    annotation: {
      common: {
        drawTime: "beforeDatasetsDraw",
      },
      annotations: {
        lineAtZero: {
          type: "line",
          yMin: min,
          yMax: min,
          borderColor: annotationBorderColor,
          borderWidth: 1,
          //borderDash: [1, 1],
        },
        lineAtMiddle: {
          type: "line",
          yMin: medium,
          yMax: medium,
          borderColor: annotationBorderColor,
          borderWidth: 1,
          //borderDash: [1, 1],
        },
        lineAtMax: {
          type: "line",
          yMin: max,
          yMax: max,
          borderColor: annotationBorderColor,
          borderWidth: 1,
          //borderDash: [1, 1],
        },
      },
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      suggestedMin: 0,
      grid: {
        display: false,
        drawTicks: false,
      },
      ticks: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      suggestedMin,
      suggestedMax,
      grid: {
        display: false,
        drawTicks: false,
      },
      ticks: {
        display: false,
      },
      border: {
        display: false,
      },
    },
  },
});
