import { ProtocolDTO } from "../dtos";
import { Protocol } from "@/models";
import {
  AirConditioningType,
  CheckinLocation,
  CheckinType,
  ContractSingin,
  DocumentPresentation,
  EmergencyContact,
  EntryMethod,
  FieldServiceOfficeAccessMode,
  FieldServiceOfficeItem,
  HotWaterType,
  KeyLocation,
  KeyLocationForCheckin,
  LightSwitchMethod,
  OpeningType,
  RequiredDocumentation,
  SmartlockName,
  SmartlockType,
  TVType,
  WarehouseAccessMode,
  WarehouseItem,
} from "@/models/room/protocol.model";

export const mapProtocolDTO = (protocolDTO?: ProtocolDTO): Protocol => {
  return {
    protocolId: protocolDTO?.protocolId,
    roomId: protocolDTO?.roomId,
    customerId: protocolDTO?.customerId,
    customerData: {
      customerOffice: {
        address: protocolDTO?.customerData?.customerOffice?.address,
        phone: protocolDTO?.customerData?.customerOffice?.phone,
        email: protocolDTO?.customerData?.customerOffice?.email,
        web: protocolDTO?.customerData?.customerOffice?.web,
        servesCustomers: protocolDTO?.customerData?.customerOffice?.servesCustomers,
        businessHours: protocolDTO?.customerData?.customerOffice?.businessHours,
        publicService: protocolDTO?.customerData?.customerOffice?.publicService,
      },
      fieldServiceOffice: {
        accessGranted: protocolDTO?.customerData?.fieldServiceOffice?.accessGranted,
        availableItems: protocolDTO?.customerData?.fieldServiceOffice?.availableItems?.map(
          (item) => item as unknown as FieldServiceOfficeItem
        ),
        keyLocationVideo: protocolDTO?.customerData?.fieldServiceOffice?.keyLocationVideo,
        accessMode: protocolDTO?.customerData?.fieldServiceOffice
          ?.accessMode as unknown as FieldServiceOfficeAccessMode,
        keychainName: protocolDTO?.customerData?.fieldServiceOffice?.keychainName,
        keychainPhoto: protocolDTO?.customerData?.fieldServiceOffice?.keychainPhoto,
        buildingAccessCode: protocolDTO?.customerData?.fieldServiceOffice?.buildingAccessCode,
        officeAccessCode: protocolDTO?.customerData?.fieldServiceOffice?.officeAccessCode,
        accessVideo: protocolDTO?.customerData?.fieldServiceOffice?.accessVideo,
        alarm: protocolDTO?.customerData?.fieldServiceOffice?.alarm,
        alarmInstructions: protocolDTO?.customerData?.fieldServiceOffice?.alarmInstructions,
      },
      warehouse: {
        address: protocolDTO?.customerData?.warehouse?.address,
        availableItems: protocolDTO?.customerData?.warehouse?.availableItems?.map(
          (item) => item as unknown as WarehouseItem
        ),
        accessMode: protocolDTO?.customerData?.warehouse
          ?.accessMode as unknown as WarehouseAccessMode,
        keychainName: protocolDTO?.customerData?.warehouse?.keychainName,
        keychainPhoto: protocolDTO?.customerData?.warehouse?.keychainPhoto,
        buildingAccessCode: protocolDTO?.customerData?.warehouse?.buildingAccessCode,
        officeAccessCode: protocolDTO?.customerData?.warehouse?.officeAccessCode,
        accessVideo: protocolDTO?.customerData?.warehouse?.accessVideo,
        alarm: protocolDTO?.customerData?.warehouse?.alarm,
        alarmInstructions: protocolDTO?.customerData?.warehouse?.alarmInstructions,
      },
      contacts: {
        emails: protocolDTO?.customerData?.contacts?.emails,
        emergencyContacts: protocolDTO?.customerData?.contacts?.emergencyContacts?.map(
          (contact) => contact as unknown as EmergencyContact
        ),
      },
    },
    actionPatterns: protocolDTO?.actionPatterns?.map((actionPattern) => ({
      title: actionPattern?.title,
      description: actionPattern?.description,
      actions: actionPattern?.actions?.map((action) => ({ content: action?.content })),
    })),
    checkAndReservationProcedures: {
      reservation: {
        canBeReservedThroughWeb:
          protocolDTO?.checkAndReservationProcedures?.reservation?.canBeReservedThroughWeb,
        web: protocolDTO?.checkAndReservationProcedures?.reservation?.web,
        dataToCollectInReservation:
          protocolDTO?.checkAndReservationProcedures?.reservation?.dataToCollectInReservation,
      },
      generalReservationInformation: {
        documentPresentation: protocolDTO?.checkAndReservationProcedures
          ?.generalReservationInformation?.documentPresentation as unknown as DocumentPresentation,
        requiredDocumentation:
          protocolDTO?.checkAndReservationProcedures?.generalReservationInformation?.requiredDocumentation?.map(
            (doc) => doc as unknown as RequiredDocumentation
          ),
        willTheyContactBeforeCheckin:
          protocolDTO?.checkAndReservationProcedures?.generalReservationInformation
            ?.willTheyContactBeforeCheckin,
        isTaxPaymentRequired:
          protocolDTO?.checkAndReservationProcedures?.generalReservationInformation
            ?.isTaxPaymentRequired,
        isDepositRequired:
          protocolDTO?.checkAndReservationProcedures?.generalReservationInformation
            ?.isDepositRequired,
        cancellation:
          protocolDTO?.checkAndReservationProcedures?.generalReservationInformation?.cancellation,
        cancellationConditions:
          protocolDTO?.checkAndReservationProcedures?.generalReservationInformation
            ?.cancellationConditions,
      },
      physicalCheckin: {
        isPossible: protocolDTO?.checkAndReservationProcedures?.physicalCheckin?.isPossible,
        entryHours: protocolDTO?.checkAndReservationProcedures?.physicalCheckin?.entryHours,
        contractSigning: protocolDTO?.checkAndReservationProcedures?.physicalCheckin
          ?.contractSigning as unknown as ContractSingin,
        information: protocolDTO?.checkAndReservationProcedures?.physicalCheckin?.information,
        freelancerCheckinPersonnel:
          protocolDTO?.checkAndReservationProcedures?.physicalCheckin?.freelancerCheckinPersonnel,
        freelancerPersonnelFileNumber:
          protocolDTO?.checkAndReservationProcedures?.physicalCheckin
            ?.freelancerPersonnelFileNumber,
        companyCheckinPersonnel:
          protocolDTO?.checkAndReservationProcedures?.physicalCheckin?.companyCheckinPersonnel,
        companyPersonnelFileNumber:
          protocolDTO?.checkAndReservationProcedures?.physicalCheckin?.companyPersonnelFileNumber,
        checkinLocation: protocolDTO?.checkAndReservationProcedures?.physicalCheckin
          ?.checkinLocation as unknown as CheckinLocation,
        keyLocationForCheckin: protocolDTO?.checkAndReservationProcedures?.physicalCheckin
          ?.keyLocationForCheckin as unknown as KeyLocationForCheckin,
      },
      autoCheckinInstructions: {
        isPossible: protocolDTO?.checkAndReservationProcedures?.autoCheckinInstructions?.isPossible,
        entryMethod: protocolDTO?.checkAndReservationProcedures?.autoCheckinInstructions
          ?.entryMethod as unknown as EntryMethod,
        appName: protocolDTO?.checkAndReservationProcedures?.autoCheckinInstructions?.appName,
        accessLink: protocolDTO?.checkAndReservationProcedures?.autoCheckinInstructions?.accessLink,
        keyLocation: protocolDTO?.checkAndReservationProcedures?.autoCheckinInstructions
          ?.keyLocation as unknown as KeyLocation,
        information:
          protocolDTO?.checkAndReservationProcedures?.autoCheckinInstructions?.information,
      },
      earlyCheckinInstructions: {
        isPossible:
          protocolDTO?.checkAndReservationProcedures?.earlyCheckinInstructions?.isPossible,
        howToRequest:
          protocolDTO?.checkAndReservationProcedures?.earlyCheckinInstructions?.howToRequest,
        advanceNoticeRequired:
          protocolDTO?.checkAndReservationProcedures?.earlyCheckinInstructions
            ?.advanceNoticeRequired,
        additionalCost:
          protocolDTO?.checkAndReservationProcedures?.earlyCheckinInstructions?.additionalCost,
        conditions:
          protocolDTO?.checkAndReservationProcedures?.earlyCheckinInstructions?.conditions,
        information:
          protocolDTO?.checkAndReservationProcedures?.earlyCheckinInstructions?.information,
      },
      lateCheckinInstructions: {
        isPossible: protocolDTO?.checkAndReservationProcedures?.lateCheckinInstructions?.isPossible,
        howToRequest:
          protocolDTO?.checkAndReservationProcedures?.lateCheckinInstructions?.howToRequest,
        advanceNoticeRequired:
          protocolDTO?.checkAndReservationProcedures?.lateCheckinInstructions
            ?.advanceNoticeRequired,
        additionalCost:
          protocolDTO?.checkAndReservationProcedures?.lateCheckinInstructions?.additionalCost,
        conditions: protocolDTO?.checkAndReservationProcedures?.lateCheckinInstructions?.conditions,
        information:
          protocolDTO?.checkAndReservationProcedures?.lateCheckinInstructions?.information,
      },
      checkoutInstructions: {
        dirtyClothes:
          protocolDTO?.checkAndReservationProcedures?.checkoutInstructions?.dirtyClothes,
        dishes: protocolDTO?.checkAndReservationProcedures?.checkoutInstructions?.dishes,
        garbage: protocolDTO?.checkAndReservationProcedures?.checkoutInstructions?.garbage,
        keys: protocolDTO?.checkAndReservationProcedures?.checkoutInstructions?.keys,
        information: protocolDTO?.checkAndReservationProcedures?.checkoutInstructions?.information,
      },
      lateCheckoutInstructions: {
        isPossible:
          protocolDTO?.checkAndReservationProcedures?.lateCheckoutInstructions?.isPossible,
        howToRequest:
          protocolDTO?.checkAndReservationProcedures?.lateCheckoutInstructions?.howToRequest,
        advanceNoticeRequired:
          protocolDTO?.checkAndReservationProcedures?.lateCheckoutInstructions
            ?.advanceNoticeRequired,
        additionalCost:
          protocolDTO?.checkAndReservationProcedures?.lateCheckoutInstructions?.additionalCost,
        conditions:
          protocolDTO?.checkAndReservationProcedures?.lateCheckoutInstructions?.conditions,
      },
    },
    roomData: {
      generalRoomInformation: {
        commercialName: protocolDTO?.roomData?.generalRoomInformation?.commercialName,
        address: protocolDTO?.roomData?.generalRoomInformation?.address,
        floor: protocolDTO?.roomData?.generalRoomInformation?.floor,
        city: protocolDTO?.roomData?.generalRoomInformation?.city,
        locationLinkGoogleMaps:
          protocolDTO?.roomData?.generalRoomInformation?.locationLinkGoogleMaps,
      },
      securityAccess: {
        openingType: protocolDTO?.roomData?.securityAccess?.openingType as unknown as OpeningType,
        smartlockName: protocolDTO?.roomData?.securityAccess
          ?.smartlockName as unknown as SmartlockName,
        smartlockType: protocolDTO?.roomData?.securityAccess
          ?.smartlockType as unknown as SmartlockType,
        keyDeliveredToRm: protocolDTO?.roomData?.securityAccess?.keyDeliveredToRm,
        emergencyKeyLocation: protocolDTO?.roomData?.securityAccess?.emergencyKeyLocation,
        masterCodeRoomonitor: protocolDTO?.roomData?.securityAccess?.masterCodeRoomonitor,
        guestEmergencyCode: protocolDTO?.roomData?.securityAccess?.guestEmergencyCode,
        portalOpeningType: protocolDTO?.roomData?.securityAccess
          ?.portalOpeningType as unknown as OpeningType,
        portalAccessCodeLink: protocolDTO?.roomData?.securityAccess?.portalAccessCodeLink,
        apartmentOpeningType: protocolDTO?.roomData?.securityAccess
          ?.apartmentOpeningType as unknown as OpeningType,
        apartmentAccessCodeLink: protocolDTO?.roomData?.securityAccess?.apartmentAccessCodeLink,
        cleanZoneWarehouseAccess: protocolDTO?.roomData?.securityAccess
          ?.cleanZoneWarehouseAccess as unknown as OpeningType,
      },
      apartmentInformation: {
        cleanZoneWarehouse: protocolDTO?.roomData?.apartmentInformation?.cleanZoneWarehouse,
        maximumOccupancy: protocolDTO?.roomData?.apartmentInformation?.maximumOccupancy,
        numberOfRooms: protocolDTO?.roomData?.apartmentInformation?.numberOfRooms,
        numberOfHalfBathrooms: protocolDTO?.roomData?.apartmentInformation?.numberOfHalfBathrooms,
        numberOfFullBathrooms: protocolDTO?.roomData?.apartmentInformation?.numberOfFullBathrooms,
        balconyTerrace: protocolDTO?.roomData?.apartmentInformation?.balconyTerrace,
        hotWater: protocolDTO?.roomData?.apartmentInformation?.hotWater as unknown as HotWaterType,
        airConditioning: protocolDTO?.roomData?.apartmentInformation
          ?.airConditioning as unknown as AirConditioningType,
        airConditioningInstructions:
          protocolDTO?.roomData?.apartmentInformation?.airConditioningInstructions,
        airConditioningVideo: protocolDTO?.roomData?.apartmentInformation?.airConditioningVideo,
        heating: protocolDTO?.roomData?.apartmentInformation?.heating,
        apartmentParticularities:
          protocolDTO?.roomData?.apartmentInformation?.apartmentParticularities,
        elevatorAvailable: protocolDTO?.roomData?.apartmentInformation?.elevatorAvailable,
      },
      wifi: {
        wifiName: protocolDTO?.roomData?.wifi?.wifiName,
        wifiPassword: protocolDTO?.roomData?.wifi?.wifiPassword,
      },
      safeBox: {
        safeBoxAvailable: protocolDTO?.roomData?.safeBox?.safeBoxAvailable,
        safeBoxCode: protocolDTO?.roomData?.safeBox?.safeBoxCode,
        safeBoxInstructionsVideo: protocolDTO?.roomData?.safeBox?.safeBoxInstructionsVideo,
      },
      parking: {
        parkingAvailable: protocolDTO?.roomData?.parking?.parkingAvailable,
        parkingAccess: protocolDTO?.roomData?.parking?.parkingAccess,
        parkingAccessVideo: protocolDTO?.roomData?.parking?.parkingAccessVideo,
      },
      alarm: {
        alarmAvailable: protocolDTO?.roomData?.alarm?.alarmAvailable,
        alarmInstructions: protocolDTO?.roomData?.alarm?.alarmInstructions,
        alarmUseVideo: protocolDTO?.roomData?.alarm?.alarmUseVideo,
      },
      appliancesLighting: {
        lightSwitchMethod: protocolDTO?.roomData?.appliancesLighting
          ?.lightSwitchMethod as unknown as LightSwitchMethod,
        tvType: protocolDTO?.roomData?.appliancesLighting?.tvType as unknown as TVType,
      },
      checkinCheckoutInformation: {
        checkinType: protocolDTO?.roomData?.checkinCheckoutInformation
          ?.checkinType as unknown as CheckinType,
        keyPickupLocation: protocolDTO?.roomData?.checkinCheckoutInformation?.keyPickupLocation,
      },
      amenitiesInsurance: {
        amenities: protocolDTO?.roomData?.amenitiesInsurance?.amenities,
        insuranceCompany: protocolDTO?.roomData?.amenitiesInsurance?.insuranceCompany,
        insuranceCompanyPhone: protocolDTO?.roomData?.amenitiesInsurance?.insuranceCompanyPhone,
        insuredPerson: protocolDTO?.roomData?.amenitiesInsurance?.insuredPerson,
        insuredPersonId: protocolDTO?.roomData?.amenitiesInsurance?.insuredPersonId,
        insurancePolicyNumber: protocolDTO?.roomData?.amenitiesInsurance?.insurancePolicyNumber,
      },
      multimediaPhotos: {
        buildingPhoto: protocolDTO?.roomData?.multimediaPhotos?.buildingPhoto,
        staircasePhoto: protocolDTO?.roomData?.multimediaPhotos?.staircasePhoto,
        apartmentDoorPhoto: protocolDTO?.roomData?.multimediaPhotos?.apartmentDoorPhoto,
        buildingEntranceVideo: protocolDTO?.roomData?.multimediaPhotos?.buildingEntranceVideo,
        apartmentEntranceVideo: protocolDTO?.roomData?.multimediaPhotos?.apartmentEntranceVideo,
      },
    },
  };
};
