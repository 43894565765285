import React from "react";

interface MaterialSymbolProps {
  symbol: string;
  fill?: boolean;
  fontSize?: string | number;
  styles?: React.CSSProperties;
}

export const MaterialSymbolComponent: React.FC<MaterialSymbolProps> = ({
  symbol,
  fill = false,
  fontSize = "1.5rem",
  styles,
}) => {
  return (
    <div
      style={{
        ...styles,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontVariationSettings: `'FILL' ${fill ? 1 : 0}, 'wght' 400, 'GRAD' 0`,
      }}
    >
      <span className="material-symbols-outlined" style={{ fontSize }}>
        {symbol}
      </span>
    </div>
  );
};
