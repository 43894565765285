import React from "react";

import Tooltip from "@mui/material/Tooltip";

interface TooltipComponentProps {
  title: React.ReactNode;
  children: React.ReactElement;
  arrow?: boolean;
  bgColor?: string;
  color?: string;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  padding?: string;
}

export const TooltipComponent: React.FC<TooltipComponentProps> = ({
  title,
  placement = "top",
  children,
  arrow,
  bgColor,
  color,
  padding,
}) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow={arrow}
      slotProps={{
        tooltip: {
          sx: {
            color: color,
            bgcolor: bgColor,
            padding,
            "& .MuiTooltip-arrow": {
              color: bgColor,
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
