import { CustomGuestCallOptionsRoom } from "../../../../models/room/room.model";
import { RoomDTO } from "../dtos";
import { CustomGuestCallOptionsRoomDTO } from "../dtos/room.api.dto";
import { Room } from "@/models";

export const mapRoomDTO = (roomDetailsDTO: RoomDTO): Room => {
  return {
    name: roomDetailsDTO?.name,
    monitorId: roomDetailsDTO?.monitorId,
    deviceFirm: roomDetailsDTO?.deviceFirm,
    data: {
      level: roomDetailsDTO?.data?.level,
      value: roomDetailsDTO?.data?.value,
      updatedAt: roomDetailsDTO?.data?.updatedAt || Date.now(),
      wifi: roomDetailsDTO?.data?.wifi,
      quality: roomDetailsDTO?.data?.quality,
      localip: roomDetailsDTO?.data?.localip,
      batteryLevel: roomDetailsDTO?.data?.batteryLevel,
      batteryMode: roomDetailsDTO?.data?.batteryMode,
    },
    noiseSettings: {
      low: roomDetailsDTO?.noiseSettings?.low,
      medium: roomDetailsDTO?.noiseSettings?.medium,
      high: roomDetailsDTO?.noiseSettings?.high,
    },
    location: {
      lat: roomDetailsDTO?.location?.lat,
      lng: roomDetailsDTO?.location?.lng,
    },
    address: roomDetailsDTO?.address,
    phone: roomDetailsDTO?.phone,
    phoneSMS: roomDetailsDTO?.phoneSMS,
    timezone: {
      name: roomDetailsDTO?.timezone?.name,
      label: roomDetailsDTO?.timezone?.label,
    },
    licenseId: roomDetailsDTO?.licenseId,
    serviceContractId: roomDetailsDTO?.serviceContractId,
    _id: roomDetailsDTO?._id,
    monitor: roomDetailsDTO.monitor ?? false,
    username: roomDetailsDTO.username,
    ownerId: roomDetailsDTO.ownerId,
    managerId: roomDetailsDTO.managerId,
    address2: roomDetailsDTO.address2,
    zipCode: roomDetailsDTO.zipCode,
    city: roomDetailsDTO.city,
    province: roomDetailsDTO.province,
    country: roomDetailsDTO.country,
    nightAgent: roomDetailsDTO.nightAgent ?? false,
    callsEnabled: roomDetailsDTO.callsEnabled ?? false,
    sendNoiseMailNotification: roomDetailsDTO.sendNoiseMailNotification ?? false,
    disconnectionNotificationEnabled: roomDetailsDTO.disconnectionNotificationEnabled ?? false,
    disableFirstCall: roomDetailsDTO.disableFirstCall ?? false,
    callAllTime: roomDetailsDTO.callAllTime ?? false,
    alarms: roomDetailsDTO.alarms,
    customGuestCallOptions: mapCustomGuestCallOptionsDTO(roomDetailsDTO.customGuestCallOptions),
    microphoneSensibility: roomDetailsDTO.microphoneSensibility,
    sendSmokeSMSNotification: roomDetailsDTO.sendSmokeSMSNotification,
    sendSmokeMailNotification: roomDetailsDTO.sendSmokeMailNotification,
    smokingAlertThreshold: roomDetailsDTO.smokingAlertThreshold,
    sendCrowdMailNotification: roomDetailsDTO.sendCrowdMailNotification,
    sendCrowdSMSNotification: roomDetailsDTO.sendCrowdSMSNotification,
  };
};

const mapCustomGuestCallOptionsDTO = (
  customGuestCallOptionsDTO?: CustomGuestCallOptionsRoomDTO
): CustomGuestCallOptionsRoom => {
  if (!customGuestCallOptionsDTO) {
    return {
      EN: "",
      PT: "",
      ES: "",
      FR: "",
    };
  }
  return {
    EN: customGuestCallOptionsDTO.EN || "",
    PT: customGuestCallOptionsDTO.PT || "",
    ES: customGuestCallOptionsDTO.ES || "",
    FR: customGuestCallOptionsDTO.FR || "",
  };
};
