export interface Pax {
  pax: OccupancyLevel | null;
  startDate: number;
  endDate: number;
}

export enum OccupancyLevel {
  Low = "low",
  Medium = "medium",
  High = "high",
  Full = "full",
}
