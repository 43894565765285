import { Alarm } from "../../../../models/alarm";
import { AlarmDTO, AlarmsByRoomIdApiDTO } from "../dtos";

export const mapAlarmsApiDTO = (data: AlarmsByRoomIdApiDTO["data"]): Alarm[] => {
  return data.alarms.map(mapAlarm);
};

const mapAlarm = (room: AlarmDTO): Alarm => {
  return {
    _id: room._id,
    type: room.type,
    name: room.name,
    createdAt: new Date(room.createdAt),
    level: room.level,
  };
};
