import { validateEmail } from "./validate-email";
import { validateLatitude, validateLongitude } from "./validate-location";
import { validatePassword } from "./validate-password";
import { validatePhoneNumber } from "./validate-phone";
import { validateUrl } from "./validate-url";

export const Validations = {
  validateEmail,
  validatePhoneNumber,
  validatePassword,
  validateLatitude,
  validateLongitude,
  validateUrl,
};
