import { GET_PAX_BUCKET_BETWEEN_RANGE_BY_MONITOR_ID } from "./api-url.constants";
import { PaxDTO } from "./dtos";
import { mapPaxDtoToPax } from "./mappers";
import { jsonFetch } from "@/lib/helpers";
import { Pax } from "@/models";

interface GetPaxBucketsBetweenRangeByMonitorIdParams {
  monitorId: string;
  bucketCount: number;
  from: number;
  to: number;
}

export const getPaxBucketsBetweenRangeByMonitorId = async ({
  monitorId,
  from,
  to,
  bucketCount,
}: GetPaxBucketsBetweenRangeByMonitorIdParams): Promise<Pax[]> => {
  const paxDTOs = await jsonFetch<{ data: PaxDTO[] }>(
    `${GET_PAX_BUCKET_BETWEEN_RANGE_BY_MONITOR_ID}/${encodeURIComponent(monitorId)}?from=${from}&to=${to}&bucketCount=${bucketCount}`
  );
  return paxDTOs.data.map(mapPaxDtoToPax).sort((a, b) => a.endDate - b.endDate);
};
