export type Monitor = {
  createdAt: number;
  data: DataValues;
  level: number;
  batteryLevel: number;
  batteryMode: string;
};

export type DataValues = "silence" | "low" | "medium" | "high";

export enum MonitorDataValues {
  silence = "silence",
  low = "low",
  medium = "medium",
  high = "high",
}

export type NoiseLevel = Pick<Monitor, "level" | "createdAt" | "data">;
