import React from "react";

import { SelectChangeEvent } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

type MDSelectProps = {
  label: string;
  options: string[];
  value: string | string[];
  onChange: (value: string | string[]) => void;
  multiple?: boolean;
  fullWidth?: boolean;
};

export const MDSelect: React.FC<MDSelectProps> = ({
  label,
  options,
  value,
  onChange,
  multiple = false,
  fullWidth = false,
}) => {
  const handleChange = (event: SelectChangeEvent<string | string[]>) => {
    onChange(event.target.value as string | string[]);
  };

  return (
    <FormControl fullWidth={fullWidth} variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        style={{ minHeight: "3.2em" }}
        multiple={multiple}
        value={value}
        onChange={handleChange}
        renderValue={(selected) => (Array.isArray(selected) ? selected.join(", ") : selected)}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {multiple && <Checkbox checked={(value as string[]).includes(option)} />}
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MDSelect;
