import { GET_PROTOCOL_URL } from "./api-url.constants";
import { ProtocolApiDTO } from "./dtos/protocol.api.dto";
import { mapProtocolDTO } from "./mappers";
import { Protocol } from "../../../models";
import { jsonFetch } from "@/lib/helpers";

export const getProtocolById = async (roomId: string): Promise<Protocol> => {
  const protocolApiDTO = await jsonFetch<ProtocolApiDTO>(`${GET_PROTOCOL_URL}/${roomId}`);
  return mapProtocolDTO(protocolApiDTO.data);
};
